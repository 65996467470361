import * as React from "react"
import "../styles/global.scss"
import { graphql } from "gatsby"

export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/index.md/" }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        apiKey
        staging
      }
    }
  }
`
import Layout from "../components/layout"
import Seo from "../components/seo"
import Helmet from "react-helmet"

const BookingPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const url =
    "https://www." +
    frontmatter.staging +
    "pamyra.de/plugin/pamyra4u.js?apikey=" +
    frontmatter.apiKey

  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <Helmet>
        <base href="/" />
        <script src={url} async defer></script>
      </Helmet>
      <div className="h-screen w-full pt-20 pb-20 custom-spacing-top">
        <div className="flex flex-col">
          <pamyra-4u className="w-full"></pamyra-4u>
        </div>
      </div>
    </Layout>
  )
}

export default BookingPage
